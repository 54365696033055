<template>
  <span>{{ displayedAmount }}</span>
</template>

<script setup lang="ts">
interface Props {
  startAmount?: number
  endAmount?: number
  duration?: number
  autoinit?: boolean
  prefix?: string
  suffix?: string
  separator?: string
  decimalSeparator?: string
  decimals?: number
}

const props = withDefaults(defineProps<Props>(), {
  startAmount: 0,
  endAmount: 0,
  duration: 0.5,
  autoinit: true,
  prefix: '',
  suffix: '',
  separator: ',',
  decimalSeparator: '.',
  decimals: 0,
})

const timestamp = ref(0)
const startTimestamp = ref(0)
const currentAmount = ref(0)
const currentStartAmount = ref(0)
const currentDuration = ref(0)
const paused = ref(false)
const remaining = ref(0)
const animationFrame = ref(0)

const isCountingUp = computed(() => props.endAmount > props.startAmount)
const displayedAmount = computed(
  () => `${props.prefix}${formatedAmount.value}${props.suffix}`,
)
const formatedAmount = computed(() => {
  const regex = /(\d+)(\d{3})/

  let numberString: string = currentAmount.value.toFixed(props.decimals)
  numberString += ''
  const numberArray: Array<string> = numberString.split('.')
  let numbers: string = numberArray[0]
  const decimals: string =
    numberArray.length > 1 ? props.decimalSeparator + numberArray[1] : ''
  const isNumber = !isNaN(parseFloat(props.separator))

  if (props.separator && !isNumber) {
    while (regex.test(numbers))
      numbers = numbers.replace(regex, '$1' + props.separator + '$2')
  }

  return numbers + decimals
})

onMounted(() => {
  currentAmount.value = props.startAmount
  currentStartAmount.value = props.startAmount
  currentDuration.value = props.duration * 1000
  remaining.value = props.duration * 1000
  if (props.autoinit) start()
  else paused.value = true
})

onUnmounted(() => {
  cancelAnimation()
})

const start = () => {
  cancelAnimation()
  currentStartAmount.value = props.startAmount
  startTimestamp.value = 0
  currentDuration.value = props.duration * 1000
  paused.value = false
  animationFrame.value = window.requestAnimationFrame(counting)
}

const pause = () => {
  if (paused.value) return
  cancelAnimation()
  paused.value = true
}

const resume = () => {
  if (!paused.value) return
  startTimestamp.value = 0
  currentDuration.value = +remaining.value
  currentStartAmount.value = +currentAmount.value
  animationFrame.value = window.requestAnimationFrame(counting)
  paused.value = false
}

const reset = () => {
  paused.value = false
  startTimestamp.value = 0
  cancelAnimation()
  currentAmount.value = props.startAmount
  if (props.autoinit) start()
  else paused.value = true
}

const counting = (time: number) => {
  timestamp.value = time
  if (!startTimestamp.value) startTimestamp.value = time
  const progress: number = time - startTimestamp.value
  remaining.value = currentDuration.value - progress

  if (!isCountingUp.value) {
    currentAmount.value =
      currentStartAmount.value -
      (currentStartAmount.value - props.endAmount) *
        (progress / currentDuration.value)
    currentAmount.value =
      currentAmount.value < props.endAmount
        ? props.endAmount
        : currentAmount.value
  } else {
    currentAmount.value =
      currentStartAmount.value +
      (props.endAmount - currentStartAmount.value) *
        (progress / currentDuration.value)
    currentAmount.value =
      currentAmount.value > props.endAmount
        ? props.endAmount
        : currentAmount.value
  }

  if (progress < currentDuration.value)
    animationFrame.value = window.requestAnimationFrame(counting)
}

const cancelAnimation = () => {
  if (animationFrame.value) window.cancelAnimationFrame(animationFrame.value)
}

watch(() => props.startAmount, reset)
watch(() => props.endAmount, reset)
watch(() => props.duration, reset)
</script>
